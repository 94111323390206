<template>
    <div class="field field-select" :class="{ 'is-invalid': !!errorMessage }">
        <label v-if="!formFloating && label" class="form-label mb-2" :for="idAttr">{{ label }}<span v-if="mandatory" class="text-danger ms-1">*</span></label>
        <div class="form-control-wrapper" :class="{ 'form-floating': formFloating }">
            <select
                class="form-select"
                :class="{ 'is-invalid': !!errorMessage }"
                @change="onHandleChange($event)"
                :name="name"
                :id="idAttr"
                :disabled="disabled"
                :value="value">
                <option v-if="placeholder" value="" disabled selected>{{ placeholder }}</option>
                <option v-for="item in items"
                    :value="getItemValue(item)">
                    {{ getItemText(item) }}
                </option>
            </select>
            <label v-if="formFloating && label" :for="idAttr" class="form-label">{{ label }}</label>
        </div>
        <FadeInOut :duration="150">
            <p v-if="errorMessage" class="error-message caption text-danger">{{ errorMessage }}</p>
        </FadeInOut>
    </div>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import { computed, PropType, toRef } from 'vue';
import { FadeInOut } from 'vue3-transitions';
import kebabCase from 'lodash/kebabCase';

const props = defineProps({
    id: String,
    label: String,
    name: { type: String, required: true },
    placeholder: String,
    itemText: String,
    itemValue: String,
    mandatory: Boolean,
    disabled: Boolean,
    items: {
        type: Array as PropType<any[]>,
        default: () => [],
        required: true
    },
    formFloating: Boolean
})

const idAttr = computed(() => props.id ? props.id : 'txt-' + kebabCase(props.name));

const {
    handleChange,
    errorMessage,
    value
} = useField(toRef(props, 'name'));

const emit = defineEmits(['update:modelValue']);

const getItemText = (item?: any) => {
    if (!item) return '';
    if (typeof item === 'string') return item;
    if (!props.itemText) return '';
    return item[props.itemText as keyof typeof item];
}

const getItemValue = (item?: string|object) => {
    if (!item) return '';
    if (typeof item === 'string') return item;
    return item[props.itemValue as keyof typeof item]
}

const onHandleChange = (e: unknown) => {
    handleChange(e);
    emit('update:modelValue', value.value);
}
</script>
